<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="text-center">
                    <div class="title">
                        Pacientes candidatos a cirugía
                    </div>
                </v-col>
                <v-col cols="12" v-if="loading">
                    <loading-spinner line height=""/>
                </v-col>
                <v-col cols="12" v-else>
                    <client-operations :clients="clients"/>
                </v-col>
                <v-col cols="12" class="text-center" v-if="clients.length === 0">
                    <small>{{ loading ? 'Cargando...' : 'Sin resultados' }}</small>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" class="normal-btn" small depressed @click="goHome">
                Dashboard
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import ClientOperations from "@/components/lists/ClientOperations";
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";

export default {
    name: "Operations",
    components: {LoadingSpinner, MasterPanel, ClientOperations},
    data() {
        return {
            loading: false,
            clients: []
        }
    },
    computed: {
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
    },
    methods: {
        async loadClients() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`doctor/${vm.doctorId}/clients/operations`)
            // console.log('response', response)
            vm.clients = response.data.clients

            vm.loading = false
        },
    }
}
</script>

<style scoped>

.title {
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    color: #172587;
    background-color: #c0c7f6;
    padding: 12px;
    border-radius: 12px;
}

</style>